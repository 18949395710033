module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"富士見市鶴瀬の税理士事務所―鈴木弘基税理士事務所―","short_name":"鈴木弘基税理士事務所　HP","start_url":"/","icon":"src/images/OrangeLogo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b88c9a18a9d6358e96350f87431f539"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://suzuki-zei.com/","stripQueryString":true},
    }]
