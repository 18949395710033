exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-tsx": () => import("./../../../src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-ex-link-tsx": () => import("./../../../src/pages/exLink.tsx" /* webpackChunkName: "component---src-pages-ex-link-tsx" */),
  "component---src-pages-greeting-tsx": () => import("./../../../src/pages/greeting.tsx" /* webpackChunkName: "component---src-pages-greeting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-office-info-tsx": () => import("./../../../src/pages/officeInfo.tsx" /* webpackChunkName: "component---src-pages-office-info-tsx" */),
  "component---src-pages-questions-tsx": () => import("./../../../src/pages/questions.tsx" /* webpackChunkName: "component---src-pages-questions-tsx" */),
  "component---src-pages-town-tsx": () => import("./../../../src/pages/town.tsx" /* webpackChunkName: "component---src-pages-town-tsx" */),
  "component---src-templates-news-post-template-tsx": () => import("./../../../src/templates/NewsPostTemplate.tsx" /* webpackChunkName: "component---src-templates-news-post-template-tsx" */)
}

